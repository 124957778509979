/* purgecss start ignore */
@tailwind base;
/* purgecss end ignore */

@tailwind utilities;

button {
  font-weight: inherit;
}

*:focus,
button:focus,
a:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.default-transition {
  @apply transition-all ease-in-out duration-200 transform-gpu;
}

.underline {
  text-decoration-style: solid;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  text-decoration-thickness: 1px;
}

.underline:hover {
  text-decoration-color: theme("colors.teal.200");
}

.active\:animate-press:active {
  animation: press 200ms linear;
}

.animate-fade-in {
  animation-name: fade-in;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-fade-out {
  animation-name: fade-out;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-fade-up-in {
  animation-name: fade-in, scale-up-in;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), ease-out;
}

.animate-fade-down-out {
  animation-name: fade-out, scale-down-out;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), ease-in;
}

.animate-scale-up-in {
  animation-name: scale-up-in;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.animate-scale-down-out {
  animation-name: scale-down-out;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.animate-slide-in-from-right {
  animation-name: slide-in-from-right;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.animate-slide-out-to-right {
  animation-name: slide-out-to-right;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.translate100 {
  transform: translate(100%);
}

@keyframes press {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

@keyframes scale-up-in {
  from {
    transform: scale(0.98) translate3d(0, 15px, 0);
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes scale-down-out {
  from {
    transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    transform: scale(0.98) translate3d(0, 15px, 0);
  }
}

@keyframes slide-in-from-right {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes slide-out-to-right {
  from {
    transform: translate3d(0%, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
